

body {
  background-color: #fff;
  color: #000;
  font-family: "Times New Roman", Times, serif;
  text-align: center;
}

h2 {
  font-size: 24px;
  margin-bottom: 10px;
  text-decoration: underline;
}

p {
  margin-bottom: 10px;
}

a {
  color: #00f;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

ul {
  list-style-type: none;
  padding-left: 0;
  margin-bottom: 10px;
}

li {
  margin-bottom: 5px;
}

.content {
  margin-bottom: 20px;
}

.container {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: left;
  padding-left: 5px;
  padding-right: 5px;
}